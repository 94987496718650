<template>
  <a-row>
    <a-col :span="12">
      <a-spin :spinning="loading">
        <a-descriptions
          :column="1"
          class="simiot-descriptions simiot-descriptions-max-6"
        >

          <a-descriptions-item label="佣金余额(元)">
            {{ data.commission_available | formatCurrency }}
          </a-descriptions-item>

          <a-descriptions-item label="累积佣金(元)">
            {{ data.commission_total | formatCurrency }}
          </a-descriptions-item>
        </a-descriptions>
      </a-spin>
    </a-col>
  </a-row>
</template>

<script>
import { findAgentFinanceOverview } from '@/api/agent'

export default {
  name: 'AgentCommissionOverview',
  data() {
    return {
      data: {},
      loading: true
    }
  },
  computed: {
    agentId() {
      return parseInt(this.$route.params.id)
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      findAgentFinanceOverview(this.agentId).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    }
  }
}
</script>

